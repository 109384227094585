// .about {
//     margin: 150px auto 30px auto;
//     padding: 0 30px;
//     backdrop-filter: blur(2px);
//     border: 1px solid rgb(148, 148, 148);
//     border-radius: 3px;
//     background-color: white;

//     b {
//         font-size: 16px;
//     }
// }


.about {
    padding-top: 80px;
    padding-bottom: 80px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.about-main {
    width: calc(2/3 * 100%);
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about-section {
    display: flex;
    flex-direction: row;
    align-content: baseline;
    align-items: baseline;
    width: 100%;
}

.about-gap {
    width: calc(0/12 * 100%);
    display: block;
}

.section-title {
    width: 25%; /* 2/8 of the main column */
    text-align: left;
    // padding-right: var(--gutter);
}

h2 {
    font-family: 'Inter', sans-serif;
    font-size: var(--size-small);
    font-weight: 600;
    color: #858789;
    margin: 0;
    padding: 0;
}

p {
    font-family: 'Lora', serif;
    font-size: var(--size-body);
    font-weight: 500;
    letter-spacing: -2%;
    // line-height: 1.6em;
    color: #191919;
    margin: 0;
    padding: 0;
}

.section-content {
    width: calc(5/8 * 100%); /* 6/8 of the main column */
    text-align: left;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            
        }
    }
}

.posts {
    ul {
        p {
            line-height: 1.5em;
        }
    }
}

.about-sidebar {
    width: calc(1/3 * 100%);
    display: flex;
    flex-direction: column;


.about-sidebar-img {
    display: inline-block;
    width: 75%;
    margin: 0;
    padding: 0;

    img {
        width: 100%;
        margin: 0 0 20px 0;
        padding: 0;
    }
}

}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .about {
        flex-direction: column;
    }

    .about-main, .about-sidebar, .about-sidebar-img {
        width: 100% !important;
    }

    .about-sidebar {
        padding-top: 50px;
    }

    
    .about-section {
        flex-direction: column; /* Stack vertically on mobile */
    }
    
    .section-title, 
    .section-content {
        width: 100%; /* Full width on mobile */
    }
    
    .section-title {
        margin-bottom: 1rem;
    }
}